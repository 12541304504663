import { PolicyFetchApiResponse } from './types';
import apiClient from '../apiClient';

export type PolicyDetailsClient = {
  getPolicyDetails: (policyNumber: string) => Promise<PolicyFetchApiResponse>;
};

const policyDetailsClient: PolicyDetailsClient = {
  getPolicyDetails: (policyNumber: string) => apiClient.get(`policy/${policyNumber}`),
};

export default policyDetailsClient;
