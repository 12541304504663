import { PrimaryButton } from '@rsa-digital/evo-shared-components/components/Button';
import ErrorContainer from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer';
import Label from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Label';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { applyComponentTheme } from '@rsa-digital/evo-shared-components/theme';
import styled from 'styled-components';

export const StyledContainer = styled(ErrorContainer)`
  margin-top: ${spacing(1)};

  flex-flow: row;
  width: 80%;

  padding-top: ${spacing(1)};
  padding-bottom: ${spacing(1)};
`;

export const StyledButton = styled(PrimaryButton)`
  margin-left: ${spacing(4)};
`;

export const IconWithLeftMargin = styled(Icon)`
  margin-left: ${spacing(2)};
`;

export const StyledSection = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`;

export const StyledTextInput = styled(TextInput)`
  height: auto;
  width: 60%;
  align-self: center;
`;

export const StyledLabelWithTheme = applyComponentTheme(Label, 'label');

export const StyledLabel = styled(StyledLabelWithTheme)`
  padding-right: 80%;
`;
