import { PolicyAndCoverNotes } from 'state/policy/state';
import parsePolicyApiResponse from 'helpers/apiResponseParsers/policyDetailsParser';
import policyDetailsClient from './client';

export type PolicyDetailsService = {
  getPolicyDetails: (policyNumber: string) => Promise<PolicyAndCoverNotes>;
};

const policyDetailsService: PolicyDetailsService = {
  getPolicyDetails: (policyNumber: string) =>
    policyDetailsClient.getPolicyDetails(policyNumber).then(parsePolicyApiResponse),
};

export default policyDetailsService;
