import React from 'react';
import { useErrorState } from 'state/error/actions';
import UserSessionWrapper from 'components/UserSessionWrapper';
import { UserSession } from 'types/userSession';

type StaffSessionWrapperProps = {
  children: React.ReactNode;
};

const StaffSessionWrapper: React.FC<StaffSessionWrapperProps> = ({ children }) => {
  const [, updateErrorState] = useErrorState();

  const checkIfStaff = (session: UserSession): void => {
    if (!session.isStaffMember) {
      updateErrorState({ hasErrored: true });
    }
  };

  return <UserSessionWrapper onLoadSession={checkIfStaff}>{children}</UserSessionWrapper>;
};

export default StaffSessionWrapper;
