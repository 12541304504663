import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import ErrorBoundary from 'components/ErrorBoundary';
import ReduxErrorBoundary from 'components/ErrorBoundary/ReduxErrorBoundary';
import Layout from 'components/Layout';
import SessionExpiryWrapper from 'components/SessionExpiryWrapper';
import StaffPolicyLookup from 'components/StaffPages/PolicyLookup';
import StaffSessionWrapper from 'components/StaffSessionWrapper';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { PageMeta } from 'types/contentStack';

type StaffAreaPageContentProps = {
  data: StaffAreaPageData;
};

const StaffAreaPageContent: React.FC<StaffAreaPageContentProps> = ({ data }) => {
  const page = data.csStaffAreaPage;
  const { meta } = processPageMeta(page.page_meta, page.url);
  const jsonLdIsEmpty = page.json_ld === '' || page.json_ld === '{}';

  return (
    <>
      {!jsonLdIsEmpty && <Helmet script={[helmetJsonLdProp(JSON.parse(page.json_ld))]} />}
      <Layout
        pageType="staffAreaHome"
        meta={meta}
        showAlertNotification={page.show_alert_notification ?? undefined}>
        <StaffPolicyLookup />
      </Layout>
    </>
  );
};

type StaffAreaPageProps = StaffAreaPageContentProps & {
  location: Location;
};

const StaffAreaPage: React.FC<StaffAreaPageProps> = ({ location, data }) => (
  <ErrorBoundary location={location} pageType="staffAreaHome">
    <ReduxErrorBoundary location={location} pageType="staffAreaHome">
      <StaffSessionWrapper>
        <SessionExpiryWrapper>
          <StaffAreaPageContent data={data} />
        </SessionExpiryWrapper>
      </StaffSessionWrapper>
    </ReduxErrorBoundary>
  </ErrorBoundary>
);

export default StaffAreaPage;

export type CsStaffAreaPage = {
  title: string;
  url: string;
  page_id: string;
  page_meta: PageMeta;
  json_ld: string;
  show_alert_notification: boolean | null;
};

type StaffAreaPageData = {
  csStaffAreaPage: CsStaffAreaPage;
};

export const query = graphql`
  query($id: String!) {
    csStaffAreaPage(id: { eq: $id }) {
      title
      url
      page_id
      show_alert_notification
      page_meta {
        ...MetaDataTagsStaffArea
      }
      json_ld
    }
  }
`;
