import { parseOptionalDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  CoverNoteApiResponse,
  NamedDriverApiResponse,
  PersonalDetailsApiResponse,
  PolicyDetailsApiResponse,
  PolicyFetchApiResponse,
  TemporaryDriverApiResponse,
  VehicleDetailsApiResponse,
} from 'apiHelpers/policyDetails/types';
import {
  CoverNote,
  NamedDriver,
  PersonalDetails,
  PolicyAndCoverNotes,
  PolicyDetails,
  TemporaryDriver,
  VehicleDetails,
} from 'state/policy/state';

const parsePolicyDetailsApiResponse = (
  policyDetailsApiResponse: PolicyDetailsApiResponse
): PolicyDetails => ({
  policyNumber: policyDetailsApiResponse.policyNumber ?? null,
  policyStartDate: parseOptionalDate(policyDetailsApiResponse.policyStartDate),
  renewalDate: parseOptionalDate(policyDetailsApiResponse.renewalDate),
  openDriving: policyDetailsApiResponse.openDriving ?? null,
  policyUpdatesBlockedForCustomers: policyDetailsApiResponse.updatesBlockedForCustomers,
  policyUpdatesBlockedForAll: policyDetailsApiResponse.updatesBlockedForAll,
});

const parseVehicleDetails = (
  vehicleDetailsApiResponse: VehicleDetailsApiResponse
): VehicleDetails => ({
  registrationNumber: vehicleDetailsApiResponse.registrationNumber ?? null,
  makeModel: vehicleDetailsApiResponse.makeModel ?? null,
});

const parsePersonalDetails = (
  personalDetailsApiResponse: PersonalDetailsApiResponse
): PersonalDetails => ({
  firstName: personalDetailsApiResponse.firstName ?? null,
  middleName: personalDetailsApiResponse.middleName ?? null,
  lastName: personalDetailsApiResponse.lastName ?? null,
  fullName: personalDetailsApiResponse.fullName ?? null,
  address: personalDetailsApiResponse.address
    ? {
        addressLine1: personalDetailsApiResponse.address.addressLine1 ?? null,
        addressLine2: personalDetailsApiResponse.address.addressLine2 ?? null,
        addressLine3: personalDetailsApiResponse.address.addressLine3 ?? null,
        addressLine4: personalDetailsApiResponse.address.addressLine4 ?? null,
        postCode: personalDetailsApiResponse.address.postCode ?? null,
      }
    : null,
});

const parseNamedDriver = (namedDriverApiResponse: NamedDriverApiResponse): NamedDriver => ({
  fullName: namedDriverApiResponse.fullName ?? null,
  dateOfBirth: parseOptionalDate(namedDriverApiResponse.dateOfBirth),
  businessUse: namedDriverApiResponse.businessUse ?? null,
  hirer: namedDriverApiResponse.hirer ?? null,
  driverRef: namedDriverApiResponse.refNumber ?? null,
});

const parseTemporaryDriver = (
  temporaryDriverApiResponse: TemporaryDriverApiResponse
): TemporaryDriver => {
  const { coverStart, coverEnd } = temporaryDriverApiResponse;

  return {
    fullName: temporaryDriverApiResponse.fullName ?? null,
    coverStart: coverStart ? new Date(coverStart) : null,
    coverEnd: coverEnd ? new Date(coverEnd) : null,
    driverRef: temporaryDriverApiResponse.refNumber ?? null,
    replacedDriverRef: temporaryDriverApiResponse.replacedDriverRefNumber ?? null,
  };
};

const parseCoverNote = (coverNotesApiResponse: CoverNoteApiResponse): CoverNote => {
  const { startDate, endDate } = coverNotesApiResponse;

  return {
    coverNoteNumber: coverNotesApiResponse.coverNoteNumber ?? null,
    coverStart: startDate ? new Date(startDate) : null,
    coverEnd: endDate ? new Date(endDate) : null,
    drivers: coverNotesApiResponse.drivers
      ? coverNotesApiResponse.drivers.map((driver) => driver.driverName)
      : [],
  };
};

const parsePolicyApiResponse = (
  policyApiResponse: PolicyFetchApiResponse
): PolicyAndCoverNotes => ({
  policy: {
    policyDetails: parsePolicyDetailsApiResponse(policyApiResponse.policy.policyDetails),
    vehicleDetails: parseVehicleDetails(policyApiResponse.policy.vehicleDetails),
    personalDetails: parsePersonalDetails(policyApiResponse.policy.personalDetails),
    namedDrivers: policyApiResponse.policy.namedDrivers.map(parseNamedDriver),
    temporaryDrivers: policyApiResponse.policy.temporaryDrivers.map(parseTemporaryDriver),
  },
  coverNotes: policyApiResponse.coverNotes.map(parseCoverNote),
  coverNotesHistory: policyApiResponse.coverNotesHistory?.map(parseCoverNote),
});

export default parsePolicyApiResponse;
