import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { matchesRegex, required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { scrollAndFocusError } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import {
  LookupStatus,
  LookupValidationErrors,
  LookupValue,
  useLookup,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import policyDetailsService from 'apiHelpers/policyDetails/service';
import React, { useEffect } from 'react';
import { useLookupPolicy } from 'state/lookupPolicy/actions';
import { LookupPolicyState } from 'state/lookupPolicy/state';
import { useManagedPolicy } from 'state/managedPolicy/actions';
import { RELOAD_POLICY, useUpdatePolicy } from 'state/policy/actions';
import { PolicyAndCoverNotes, usePolicyState } from 'state/policy/state';
import useDispatch from 'state/useDispatch';
import FormHeading from 'components/Forms/FormHeading';
import PolicyAccordionBlock from 'components/blocks/PolicyAccordion';
import { trackFieldError } from 'helpers/eventTracking';
import useInvalidPolicyStateErrorHandler from 'helpers/useInvalidPolicyStateErrorHandler';
import {
  IconWithLeftMargin,
  StyledButton,
  StyledContainer,
  StyledLabel,
  StyledSection,
  StyledTextInput,
} from './styles';

const POLICY_NUMBER_NOT_FOUND =
  "Policy number not found: check you've entered 3 letters followed by 8 digits, like RMO12345678.";

const StaffPolicyLookup: React.FC = () => {
  const [lookupPolicyState, updateLookupPolicyState] = useLookupPolicy();
  const [managedCrn, updateManagedCrn] = useManagedPolicy();
  const { policyNumber } = lookupPolicyState;
  const { policy, coverNotes, requiresReload } = usePolicyState();
  const updatePolicyState = useUpdatePolicy();
  const invalidPolicyStateErrorHandler = useInvalidPolicyStateErrorHandler();
  const dispatch = useDispatch();

  const policyDetailsLookup = {
    lookupKey: policyNumber,
    data: { policy, coverNotes },
    status: lookupPolicyState.lookupStatus,
  } as LookupValue<string, PolicyAndCoverNotes | null | undefined>;

  const updateDetails = (
    loadedData: PolicyAndCoverNotes | null,
    lookupStatus: LookupStatus
  ): void => {
    updatePolicyState({
      policy: loadedData ? loadedData.policy : null,
      coverNotes: loadedData ? loadedData.coverNotes : null,
      coverNotesHistory: loadedData?.coverNotesHistory,
      requiresReload: false,
    });
    updateLookupPolicyState({ policyNumber, lookupStatus });
  };

  const policyNumberValidation = {
    policyNumber: [
      required('Policy number required'),
      matchesRegex(/^RMO\d{8}/, POLICY_NUMBER_NOT_FOUND),
    ],
  } as ValidationRules<LookupPolicyState>;

  const { validateOnSubmit, getError, showValidation } = useValidation(
    lookupPolicyState,
    policyNumberValidation,
    trackFieldError
  );

  const lookupErrors: LookupValidationErrors = {
    notFound: POLICY_NUMBER_NOT_FOUND,
    unavailable: 'Server unavailable',
  };

  const policyLookup = async (policyNumberToLookup: string): Promise<PolicyAndCoverNotes> => {
    updateManagedCrn(policyNumberToLookup);

    try {
      const fetchedPolicy = await policyDetailsService.getPolicyDetails(policyNumberToLookup);
      updateManagedCrn(policyNumberToLookup);
      return fetchedPolicy;
    } catch (error) {
      invalidPolicyStateErrorHandler(error);
      throw error;
    }
  };

  const { performLookup, getLookupError } = useLookup(
    policyDetailsLookup,
    (lookup) => {
      updateDetails(lookup.data ?? null, lookup.status);
    },
    policyLookup,
    () => showValidation('policyNumber'),
    lookupErrors
  );

  const getErrorText = (): string | undefined => getLookupError() ?? getError('policyNumber');

  useEffect(() => {
    if (requiresReload && policyNumber && managedCrn === policyNumber) {
      performLookup();
    }
  }, [lookupPolicyState, managedCrn, performLookup, policyNumber, requiresReload]);

  useEffect(() => {
    dispatch({ type: RELOAD_POLICY });
  }, [dispatch]);

  return (
    <form
      onSubmit={validateOnSubmit((e) => {
        e.preventDefault();
        performLookup();
      }, scrollAndFocusError)}>
      <FormHeading text="Manage drivers" />
      <StyledContainer id="PolicySearch" errorId="PolicySearch-error" errorText={getErrorText()}>
        <StyledSection>
          <StyledLabel id="policyNumber-label" htmlFor="policyNumber">
            Policy number
          </StyledLabel>
          <StyledTextInput
            id="policyNumber"
            value={policyNumber ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateLookupPolicyState({
                policyNumber: e.target.value,
                lookupStatus: LookupStatus.Pending,
              });
              updatePolicyState({
                policy: null,
                coverNotes: null,
                coverNotesHistory: undefined,
                requiresReload: false,
              });
            }}
            loading={lookupPolicyState.lookupStatus === LookupStatus.InProgress}
            onBlur={() => showValidation('policyNumber')}
            maxLength={11}
          />
          <StyledButton type="submit">
            Search
            <IconWithLeftMargin name="chevron" size="small" color={colors.neutral08} />
          </StyledButton>
        </StyledSection>
      </StyledContainer>
      {policy && (
        <PolicyAccordionBlock
          show_policy_accordion={{ show_policy_accordion: true }}
          initiallyDisplayEntries
        />
      )}
    </form>
  );
};

export default StaffPolicyLookup;
