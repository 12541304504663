import sessionClient from 'apiHelpers/sessionClient';
import React, { useEffect } from 'react';
import { useUpdateUserState } from 'state/user/actions';
import { SessionState, useUserState } from 'state/user/state';
import useRequestHandler from 'helpers/useRequestHandler';
import { UserSession } from 'types/userSession';

type UserSessionWrapperProps = React.PropsWithChildren<{
  onLoadSession?: (session: UserSession) => void;
}>;

const UserSessionWrapper: React.FC<UserSessionWrapperProps> = ({ children, onLoadSession }) => {
  const requestHandler = useRequestHandler();
  const updateUserState = useUpdateUserState();
  const userState = useUserState();

  useEffect(() => {
    if (userState.sessionState === SessionState.Empty) {
      updateUserState({ ...userState, sessionState: SessionState.Loading });
      requestHandler(() =>
        sessionClient.getSession().then((response) => {
          onLoadSession?.(response);
          updateUserState({ ...response, sessionState: SessionState.Loaded });
        })
      );
    }
  }, [onLoadSession, requestHandler, updateUserState, userState]);

  return <>{children}</>;
};

export default UserSessionWrapper;
